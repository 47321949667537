import { Klass } from '#backend/lib/transforms'
import { Site } from './Site'
import { Agent } from './Agent'
import { Province } from './Province'
import Pagination from '$commons/lib/Pagination'

export class Territory {
  id!: number
  name!: string
  schools_count!: number

  @Klass(Agent) active_agent?: Agent
  @Klass(Site) site!: Site
  @Klass(Province) province!: Province

  get name_with_phone() {
    if (!this.active_agent) return this.name

    return `${this.name}-${this.active_agent.phone}`
  }
}

export class TerritoryPage extends Pagination<Territory> {
  @Klass(Territory) list: Territory[] = []
  counts_by_city: Record<string, number> = {}
  counts_by_district: Record<string, number> = {}
}
